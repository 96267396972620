import { createApp, defineAsyncComponent } from 'vue'

export function createVueApp(app) {
    const vueApp = createApp(app);
    const modules = import.meta.glob(['@/components/**/*.vue', '@/entrypoints/**/components/*.vue']);

    for (const path in modules) {
        const componentName = path.replace(/^.*[\\/]/, '').replace(/\.\w+$/, '');
        vueApp.component(componentName, defineAsyncComponent(modules[path]));
    }

    return vueApp;
} 